import $ from 'jquery'
import { AjaxHelper } from '../extensions/ajax_helpers'

/**
 * Loads the content of the object picker given the levels, search query, and version
 * Take a look at ObjectPicker::Config for format of levels and supported options
 * loadType is the type of load to perform. It can be 'CURRENT_LEVEL' or 'NEXT_LEVEL' or 'ALL_LEVELS'
 * parent is the gid of the parent object. This is used to determine what level to render.
 * selected is the gid of the selected object. This is used to resolve all levels when loadType is 'ALL_LEVELS', and open at the last level.
 */
export const loadObjectPickerContent = ({
  query,
  levels,
  version,
  loadType,
  parent,
  selected,
  onSuccess,
  onError,
} = {}) => {
  return $.ajax({
    url: '/admin/object_picker',
    data: {
      object_picker: {
        levels: JSON.stringify(levels),
        version,
        loadType,
        parent,
        selected,
      },
      q: loadType === 'CURRENT_LEVEL' ? query : '',
    },
    success: onSuccess,
    error: (xhr, error, message) => {
      if (error == 'abort') return
      const finalMessage = xhr.responseJSON?.error || message
      onError?.(xhr, error, finalMessage)
      AjaxHelper.errorFunc(xhr, error, finalMessage)
    },
  })
}

/**
 * Handle the pick event from the object picker. Currently, it supports performing a ajax request and/or redirecting to a url
 * @param {Object} options
 *   @param {string} options.remote - The url to send the ajax request to. The url can contain $id$ or $ids$ which will be replaced with the id(s) of the picked object(s)
 *   @param {string} options.method - The method to use for the ajax request. e.g 'POST'
 *   @param {string} options.redirect - The url to redirect to. The url can contain $id$ or $ids$ which will be replaced with the id(s) of the picked object(s)k
 *   @param {Object} options.detail - The detail of the picked object(s). This is the object returned by the object picker.
 */
export const handleObjectPickerPick = ({
  remote,
  method,
  redirect,
  detail,
}) => {
  if (remote) {
    let data = {}
    const model = detail.items[0].model
    data[`${model}_id`] = detail.id
    data[`${model}_ids`] = detail.ids
    $.sajax({
      url: transformUrl(remote, detail),
      method: method,
      data: data,
    })
  }

  if (redirect) {
    window.location.href = transformUrl(redirect, detail)
  }
}

const transformUrl = (url, detail) => {
  return decodeURIComponent(url)
    .replaceAll('$id$', detail.id)
    .replaceAll('$ids$', detail.ids)
}
